.stake-token-btn {
  background: linear-gradient(
    135deg,
    #0fa,
    #4579f5 51.65%,
    #9c42f5 99.83%
  ) !important;
  border-radius: 2rem !important;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
    0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%) !important;
}
.stake-your-btn {
  background: linear-gradient(
    135deg,
    #2d2f33,
    #282a2e 51.37%,
    #212429 99.14%
  ) !important;
  border-radius: 72px !important;
  color: #fff !important;
  box-shadow: 0 0 1px rgb(0 255 170 / 12%), 0 2px 4px -1px rgb(27 10 82 / 6%),
    0 16px 24px rgb(0 255 170 / 12%), 0 8px 8px -4px rgb(0 255 170 / 6%),
    inset 0 2px 6px rgb(0 255 170 / 40%) !important;
}
.btaf-stake-head {
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 1px;
  line-height: 36px;
  color: #fff;
}
.btaf-stake-head svg {
  position: absolute;
  left: 37%;
  top: -6px;
  width: 120px;
  z-index: -1;
}
.bIcVES {
  background: none !important;
  text-align: center;
  margin-top: 4rem;
}
.cmUSNN {
  border-radius: 1rem;
  width: 10% !important;
  height: 1.6em !important;
  top: auto !important;
  background-color: #cfcfcf5c !important;
  color: #fff;
}
.jHHihC {
  border-radius: 1rem;
  width: 10% !important;
  height: 1.6em !important;
  top: auto !important;
}
.jHHihC:hover {
  background-color: #0fa;
}
.all-card {
  backdrop-filter: blur(108.731px);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.12),
    rgba(245, 247, 250, 0.06) 52.14%,
    rgba(245, 247, 250, 0)
  );
  border: 1px solid rgba(245, 247, 250, 0.06);
  border-radius: 24px !important;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 1px 1px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%),
    8px 8px 24px rgb(9 13 20 / 40%);
  box-sizing: border-box;
  margin-bottom: 24px;
  padding: 40px;
  position: relative;
}
.all-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    135deg,
    rgb(16, 63, 255) 0%,
    rgb(156, 66, 245) 53%,
    rgb(245, 66, 200) 100%
  );
  box-shadow: rgb(156 66 245 / 24%) 0px 0px 1px,
    rgb(10 70 82 / 12%) 0px 2px 4px -1px, rgb(156 66 245 / 24%) 0px 16px 24px,
    rgb(156 66 245 / 24%) 0px 8px 8px -4px;
  border-radius: 24px 24px 0 0;
  height: 40px;
}

.all-card-35 {
  backdrop-filter: blur(108.731px);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.12),
    rgba(245, 247, 250, 0.06) 52.14%,
    rgba(245, 247, 250, 0)
  );
  border: 1px solid rgba(245, 247, 250, 0.06);
  border-radius: 24px !important;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 1px 1px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%),
    8px 8px 24px rgb(9 13 20 / 40%);
  box-sizing: border-box;
  margin-bottom: 24px;
  padding: 40px;
  position: relative;
}

.all-card-35::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    135deg,
    rgb(245, 66, 200) 0%,
    rgb(156, 66, 245) 53%,
    rgb(16, 63, 255) 100%
  );
  box-shadow: rgb(156 66 245 / 24%) 0px 8px 8px -4px,
    rgb(156 66 245 / 24%) 0px 16px 24px, rgb(10 70 82 / 12%) 0px 2px 4px -1px,
    rgb(156 66 245 / 24%) 0px 0px 1px;
  border-radius: 24px 24px 0 0;
  height: 40px;
}

.three-btn {
  background-color: #828282 !important;
  font-weight: 700 !important;
  box-shadow: 0 0 1px #4d42fc21, 0 2px 4px -1px #5441fa29, 0 6px 12px #4842fc1c,
    0 8px 8px -4px #4d42fc29, inset 0 2px 6px #5241fc36;
  font-size: 16px;
  padding: 12px 24px;
  text-transform: capitalize !important;
  border-radius: 1rem !important;
  color: #000 !important;
}
.btaf-all-head {
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 1px;
  line-height: 36px;
  color: #fff;
}
.btaf-all-head svg {
  position: absolute;
  left: 40%;
  top: -10px;
  width: 113px;
  z-index: -1;
}
.empty-box {
  text-align: center;
  padding: 2rem;
  background-color: #b0b0b008;
  border-radius: 1rem;
  color: #515151;
  margin-top: 2rem;
}
.terms-card {
  backdrop-filter: blur(108.731px);
  /* background: linear-gradient(135deg,rgba(245,247,250,.12),rgba(245,247,250,.06) 52.14%,rgba(245,247,250,0)) !important; */
  display: flex;
  height: auto;
  justify-content: space-between;
  margin: 0 auto;
  backdrop-filter: blur(108.731px);
  /* border: 1px solid rgba(245,247,250,.06); */
  /* border-radius: 24px !important; */
  /* box-shadow: 0 1px 1px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%), 8px 8px 24px rgb(9 13 20 / 40%) !important; */
  padding: 72px 26px 72px 72px;
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: visible !important;
}
.terms-card-box {
  max-width: 1096px;
  background-color: transparent !important;
  position: relative;
  overflow: visible !important;
}
.before-styles-terms:before {
  background: linear-gradient(135deg, #0fa, #4579f5 51.65%, #9c42f5 99.83%);
  border-radius: 366px;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
    0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%);
  content: "";
  height: 304px;
  right: -9%;
  position: absolute;
  top: 12%;
  width: 304px;
}
.terms-head {
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 1px;
  line-height: 36px;
  color: #fff;
}
.terms-head svg {
  position: absolute;
  left: 70px;
  top: 33px;
  width: 95px;
  z-index: -1;
}
/* .dialogue-style .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
   border-radius: 1rem;
}
.dialogue-style .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    background-color: #212429 !important;
} */
/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    background-color: #212429 !important;
    border-radius: 16px !important;
    min-width: 280px;
    overflow: auto;
    padding: 48px;
    position: relative;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  color: #fff !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 20px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    color: #fff !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
    color: rgb(255 255 255 / 60%) !important;

} */
table {
  border-top-style: hidden;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd12;
  text-align: left;
  padding: 8px;
  font-size: 13px;
}
tr td {
  border-left: none;
  border-right: none;
  color: #919191ba;
}
tr th {
  border-left: none;
  border-right: none;
  color: #919191ba;
}
.btn-stake-card {
  background-color: #b0b0b02e !important;
  border-radius: 1rem !important;
  font-size: 11px !important;
  padding: 3px !important;
  color: #cccc !important;
  font-weight: 600 !important;
}
/* .custom-modal{
    max-width: 400px;
    background-color: #ccc;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 200px;
    padding: 20px;
    z-index: 9999999;
} */

/**Icon Hover Effect 4**/

.banner-links a {
  font-size: 33px;
  color: rgb(152 152 173);
  /* margin: 0px 10px; */
  margin: 0;
  display: inline-block;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.banner-links a:hover {
  -moz-transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -o-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
}

.modal-header-claim::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    135deg,
    rgb(130 255 150) 0%,
    rgb(5 195 83) 53%,
    rgb(0 129 8) 100%
  );
  box-shadow: rgb(101 168 3 / 24%) 0px 0px 1px,
    rgb(10 82 22 / 12%) 0px 2px 4px -1px, rgb(66 245 77 / 24%) 0px 16px 24px,
    rgb(66 245 68 / 24%) 0px 8px 8px -4px;
  border-radius: 24px 24px 0 0;
  height: 40px;
}
.unstake-btn {
  background: linear-gradient(
    135deg,
    #fe4d20,
    #e21155 51.65%,
    #f542e5 99.83%
  ) !important;
  border-radius: 72px !important;
  box-shadow: 0 0 1px rgb(245 69 233 / 24%), 0 2px 4px -1px rgb(82 10 74 / 12%),
    0 16px 24px rgb(209 69 245 / 24%), 0 8px 8px -4px rgb(245 69 242 / 12%) !important;
  color: #fff;
}
.modal-header-unstake::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    135deg,
    rgb(203 7 170) 0%,
    rgb(151 5 195) 53%,
    rgb(71 11 220) 100%
  );
  box-shadow: #9c42f55c 0px 0px 1px, rgb(82 10 81 / 12%) 0px 2px 4px -1px,
    rgb(245 66 239 / 18%) 0px 16px 24px, rgb(245 66 227 / 9%) 0px 8px 8px -4px;
  border-radius: 24px 24px 0 0;
  height: 40px;
}
.modal-header-stake-btn {
  background: linear-gradient(
    135deg,
    #05ff00,
    #00ff6dad 51.65%,
    #00858f 99.83%
  ) !important;
  border-radius: 72px !important;
  box-shadow: 0 0 1px #00ffaa36, 0 2px 4px -1px rgb(10 82 29 / 12%),
    0 16px 24px #00ff2e26, 0 8px 8px -4px rgb(69 245 80 / 12%) !important;
  color: #fff;
}
.modal-header-stake::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(135deg, #0fa 0%, #1976d2 53%, #9c42f5 100%);
  box-shadow: #00858f75 0px 0px 1px, #00ffaa26 0px 2px 4px -1px,
    #00ffaa1a 0px 16px 24px, #00ffaa47 0px 8px 8px -4px;
  border-radius: 24px 24px 0 0;
  height: 40px;
}
