.what-is-pay_top {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
  margin: 0;
  padding: 3rem 0rem 18rem 0rem;
  z-index: 2;
}
.what-is-pay_top-section {
  height: 100%;
  background-size: cover;
  position: relative;
  padding: 0;
  background: url("../../assets/top_pay_bg.svg");
  background-size: cover;
}
.what-is-pay_top-section_content {
  height: 100%;
  background-size: cover;
  position: relative;
  padding: 0;
  background: url("../../assets/content_pay_bgm.svg");
  background-size: cover;
}
.before-styles-btafpay:before {
  background: linear-gradient(135deg, #0fa, #4579f5 51.65%, #9c42f5 99.83%);
  border-radius: 366px;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
    0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%);
  content: "";
  height: 304px;
  right: 0;
  position: absolute;
  top: -40%;
  left: 78%;
  width: 304px;
}
.what-head-pay {
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 1px;
  line-height: 36px;
  color: #fff;
}
.what-head-pay svg {
  position: absolute;
  left: 165px;
  top: -7px;
  width: 95px;
  z-index: -1;
}
.content-parent {
  max-width: 692px;
  padding: 80px 0 80px;
}

.content-parent .content-para {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.833333px;
  color: #fff;
  padding: 0;
  margin: 0 0 32px;
}
.stake-img {
  height: 100%;
  margin-bottom: -72px;
  width: 390px;
}
.contact-card::before {
  background: linear-gradient(135deg, #0fa, #4579f5 51.65%, #9c42f5 99.83%);
  border-radius: 366px;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
    0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%);
  content: "";
  height: 304px;
  right: -8%;
  position: absolute;
  top: -30%;
  width: 304px;
}
